<template>
  <el-header class="app-header flex clearfix">
    <div class="fl">
      <div class="logo-container">
        <img src="../../assets/images/app_logo.png" />
      </div>
    </div>
    <div class="fr app-personal">
      <span class="pointer">
        您好！{{userName}}
        <!-- <img class="account-img" src="../../../assets/images/personal_center.png" /> -->
      </span>
      <span class="pointer app-text" @click="exit">退出</span>
    </div>
  </el-header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['userName'])
  },
  methods: {
    // 退出登录
    exit () {
      this.$confirm('是否退出当前账户', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$store.dispatch('user/loginOut')
        })
        .catch(() => {
          return false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  background-color: #212121;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.15);
  align-items: center;
  padding: 0;
  .app-personal {
    flex: 1;
    text-align: right;
    margin-right: 35px;
    color: #c8c7c7;
    font-size: 17px;
    span {
      display: inline-block;
    }
    .app-text {
      margin-left: 25px;
    }
    .el-icon-full-screen {
      margin-right: 30px;
      font-size: 18px;
      color: #fff;
      &:hover {
        font-weight: 700;
        color: #c8c7c7;
      }
    }
  }
}
</style>
